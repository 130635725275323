@tailwind base;
@tailwind components;
@tailwind utilities;

// Define your global styles here
$bg-color: #f5f5fd;

@mixin font-face-noto($name, $file, $weight: 300, $style: normal) {
  @font-face {
    font-family: "#{$name}";
    src: url("./fonts/#{$file}.woff") format("font-woff"),
    url("./fonts/#{$file}.woff2") format("font-woff2"),
    url("./fonts/#{$file}.otf") format("opentype"),;
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}
@mixin font-face($name, $file, $weight: 300, $style: normal) {
  @font-face {
    font-family: "#{$name}";
    src: url("./fonts/#{$file}.otf") format("otf"),
    url("./fonts/#{$file}.ttf") format("truetype");
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@include font-face-noto('Noto Sans KR', 'NotoSansKR-Thin', 100);
@include font-face-noto('Noto Sans KR', 'NotoSansKR-Light', 300);
@include font-face-noto('Noto Sans KR', 'NotoSansKR-DemiLight', 350);
@include font-face-noto('Noto Sans KR', 'NotoSansKR-Regular', 400);
@include font-face-noto('Noto Sans KR', 'NotoSansKR-Medium', 500);
@include font-face-noto('Noto Sans KR', 'NotoSansKR-Bold', 700);
@include font-face-noto('Noto Sans KR', 'NotoSansKR-Black', 900);

@include font-face('Gmarket Sans', 'GmarketSansMedium', 300);
@include font-face('Gmarket Sans', 'GmarketSansLight', 100);
@include font-face('Gmarket Sans', 'GmarketSansBold', 600);


html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
body {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: $bg-color;
  font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root, .App {
  width: 100%;
  height: 100%;
}
main {
  @apply md:px-4 mx-auto w-full;
}
.paper {
  @apply py-6 px-6 my-4 rounded-lg shadow-md bg-base-100 md:space-y-6;
  &.my-no {
    @apply my-4 md:my-0;
  }
}
.paper-modal {
  @apply py-6 px-6 my-0 space-y-3;
}
.form-row {
  @apply md:flex md:flex-row md:space-x-6 items-center;
}
.form-col {
  @apply md:flex md:flex-col space-y-1 md:space-y-2;
}
.mobile-row {
  @apply block my-4 md:my-auto;
}

.input, .select, .textarea {
   &-bordered {
    @apply border-opacity-20;
  }
  &:focus {
    @apply border-primary outline-0 outline-offset-0;
  }
  &-readonly,
  &[readonly] {
    @apply border-base-200 bg-base-200 text-opacity-20 placeholder-base-content placeholder-opacity-20;
  }
}
.textarea {
  @apply h-36;
}
.radio {
  &:checked,
  &[aria-checked="true"] {
    @apply border-primary bg-primary text-primary-content;
  }
  &-readonly,
  &[readonly] {
    @apply border-base-200 bg-base-200 text-opacity-20 placeholder-base-content placeholder-opacity-20;
  }
}
.checkbox {
  --chkbg: var(--p);
  --chkfg: var(--pc);
  &:checked,
  &[checked="true"],
  &[aria-checked="true"] {
    @apply border-primary bg-primary text-primary-content;
  }
  &-readonly,
  &[readonly] {
    @apply border-base-200 bg-base-200 text-opacity-20 placeholder-base-content placeholder-opacity-20;
  }
}
.label {
  @apply pl-1;
}
.right-button {
  @apply mt-2 md:mt-0 md:text-right;
}
.table-wrap {
  @apply overflow-x-auto max-w-xs md:overflow-visible md:max-w-full md:h-full;
}
.table-wrapper {
  @apply overflow-visible w-full h-full;
}
.td-no-record {
  @apply h-16 text-center md:h-36;
}
.div-no-record {
  @apply h-16 md:h-36 flex justify-center items-center text-sm;
}
.mobile-show {
  @apply inline-block md:hidden;
  &-block {
    @apply block md:hidden;
  }
}
.mobile-hide {
  @apply hidden md:inline-block;
  &-block {
    @apply hidden md:block;
  }
}
.mobile-accordian {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1.5s ease-out;
}
.mobile-accordian.open {
  max-height: 100%;
  transition: max-height 1.5s ease-in;
}
.div-table {
  @apply block md:table md:border-collapse;
}
.div-table-row {
  @apply block p-3 md:p-0 mb-5 md:mb-0 bg-slate-100 md:bg-white rounded-box md:table-row;
  &-white {@apply bg-white;}
  .div-table-cell {
    @apply px-2 py-3 text-sm border-b md:border-0 border-slate-200 border-solid md:text-center;
    &:last-child {@apply border-0;}
  }
  &.div-table-header {
    @apply hidden md:table-row;
    .div-table-cell {
      @apply whitespace-nowrap md:text-center;
    }
  }
}
.div-table-cell {
  @apply block align-middle md:table-cell;
  &.cell-mobile-hide {
    @apply hidden md:table-cell;
  }
  &.cell-mobile-show {
    @apply block md:hidden;
  }
}
.div-table :where(.div-table-row:first-child, .div-table-row:last-child),
.div-table .div-table-row {
  @apply border-0 md:border-b border-slate-200 border-solid;
}
.btn-default {
  @apply bg-slate-500 text-white hover:bg-slate-600
}

.app-bar-head {
  @apply flex justify-between items-center;
  .bar-left, .bar-right {
    width: 85px;
  }
}

ul.menu {
  font-family: 'Noto Sans KR';
  font-weight: 500;
}
.menu li .menu-logo-link {
  margin-top: -7px;
  margin-left: -7px;
  @apply hover:bg-white active:bg-white mb-1
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Gmarket Sans';
}
.sub-title {
  font-family: 'Gmarket Sans';
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -1.0px;
  .text-gradient {
    display: inline-block;
    color: #fff;
    background: linear-gradient(120.46deg, #76E5FF 10.84%, #7E6BF0 89.15%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    //background-clip: text;
    text-fill-color: transparent;
  }
}
.text-point {
  position: relative;
  padding-left: 10px;
  line-height: 1;
  color: #696cff;
}
.text-point::before {
  content: "‣";
  //color: #D2228C;
  margin: auto;
  line-height: 0.85;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}
span.yacht-name {
  @apply inline-block py-1 px-2 rounded-xl whitespace-nowrap
}
.App-logo {
  max-height: 30px;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
.react-datepicker-wrapper {
  @apply w-full;
}
